import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import InfoSectionWrapper from './info.style';
import ImageOne from 'common/src/assets/image/saasModern/search.svg';
import { openEveryFundedCompanyCheckout } from '../../../pages/every-funded-company-ever';

const EveryFundedInfo = ({
                       row,
                       col,
                       title,
                       description,
                       button,
                       textArea,
                       imageArea,
                       imageAreaRow,
                       imageWrapper,
                       imageWrapperOne,
                       imageWrapperTwo,
                       textAreaRow
                     }) => {
  return (
    <InfoSectionWrapper>
      <Container fullWidth noGutter className="info-sec-container">
        <Box {...row} {...imageAreaRow} className="bobla">
          <Box {...col} {...imageArea} className="image_area">
            <Card {...imageWrapper} {...imageWrapperOne}>
              <Fade left>
                <Image
                  src={ImageOne}
                  alt="Info Image One"
                  style={{ width: '598px', height: '767px' }}
                />
              </Fade>
            </Card>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box {...row} {...textAreaRow}>
          <Box {...col} {...textArea}>
            <Heading
              {...title}
              content="Start selling to well funded B2B customers"
            />
            <Text
              {...description}
              content={
                <>
                  <ul>
                    <li>
                      • Amplify your revenue by selling directly to all companies who have ever received funding
                    </li>
                    <li>
                      • Get access to the best customers in the world
                    </li>
                    <li>
                      • Easy one-click download. No demos, calls or quotes.
                    </li>
                  </ul>
                </>
              }
            />
            <Box>
              <AnchorLink href="#leads">
                <Button {...button} title="BUY FOR $99" onClick={openEveryFundedCompanyCheckout}/>
              </AnchorLink>
            </Box>
          </Box>
        </Box>
      </Container>
    </InfoSectionWrapper>
  );
};

EveryFundedInfo.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageWrapperOne: PropTypes.object,
  imageWrapperTwo: PropTypes.object,
  textAreaRow: PropTypes.object
};

EveryFundedInfo.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  textAreaRow: {
    flexDirection: 'row-reverse'
  },
  col: {
    pr: '15px',
    pl: '15px'
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '42%']
  },
  imageArea: {
    width: ['100%', '100%', '50%', '50%', '55%'],
    flexBox: true
  },
  imageWrapper: {
    boxShadow: 'none'
  },
  imageWrapperOne: {
    mr: ['-250px', '-250px', '-200px', '-250px', '-400px']
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: '-60px'
  },
  title: {
    fontSize: ['26px', '32px', '36px', '40px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '20px',
    lineHeight: '1.25'
  },
  description: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#343d48cc',
    lineHeight: '2',
    mb: '33px'
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg'
  }
};

export default EveryFundedInfo;
