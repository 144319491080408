import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import ProcessItem from './workingProcess.style';
import { openParametersModal } from '../../Saas/LeadSection/LeadModal';
import { buttonize } from '../../../utils/Utils';
import { openAmericaCheckout } from '../../../pages/the-great-american-startup-list';

const AmericaProcess = ({
                          sectionWrapper,
                          secTitleWrapper,
                          secText,
                          secHeading,
                          processRow,
                          processCol,
                          processImageStyle,
                          processTitleStyle,
                          processDescriptionStyle
                        }) => {

  return (
    <Box {...sectionWrapper} as="section" id="feature_section">
      <Container>
        <Box {...secTitleWrapper} style={{ marginBottom: '70px' }}>
          <div style={{ height: '5rem' }}/>
          <Text {...secText} content="HOW IT WORKS"/>
          <Heading
            {...secHeading}
            content="Get a CSV in your email and start selling to US companies"
          />
        </Box>

        <Box {...processRow}>
          <Box
            {...processCol}
            className="process_item_col"
          >
            <ProcessItem className="process_item">
              <Heading as="h3" content="We find leads 🎯" {...processTitleStyle} />
              <Text
                content={<>
                  We continuously scan the internet and track all american companies, from brand new
                  startups to Fortune 500.
                </>}
                {...processDescriptionStyle}
              />
            </ProcessItem>
          </Box>
          <Box
            {...processCol}
            className="process_item_col"
          >
            <ProcessItem className="process_item">
              <Heading as="h3" content="We enrich leads 💎" {...processTitleStyle} />
              <Text
                content={<>We manually verify and enrich all leads with <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  {...buttonize(openParametersModal)}>
                  these 20 parameters
                  </span> so you
                  know everything about your leads.
                </>}
                {...processDescriptionStyle}
              />
            </ProcessItem>
          </Box>
          <Box
            {...processCol}
            className="process_item_col"
          >
            <ProcessItem className="process_item">
              <Heading as="h3" content="So you can start selling 💰" {...processTitleStyle} />
              <Text
                content={<>
                  No excuses. You can start selling to american companies in less than 60
                  seconds. <a href="#" onClick={openAmericaCheckout}>Buy now for $99</a>.
                </>}
                {...processDescriptionStyle}
              />
            </ProcessItem>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

AmericaProcess.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object
};

AmericaProcess.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0
  },
  secTitleWrapper: {
    mb: ['60px', '100px']
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px'
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67'
  },
  processRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['0', '-15px', '-20px', '-35px', '-45px'],
    mr: ['0', '-15px', '-20px', '-35px', '-45px']
  },
  processCol: {
    width: [1, 1 / 3],
    pl: ['0', '15px', '20px', '35px', '45px'],
    pr: ['0', '15px', '20px', '35px', '45px'],
    mb: '40px'
  },
  processImageStyle: {
    mb: '35px',
    width: ['60px', '60px', '70px', 'auto']
  },
  processTitleStyle: {
    fontSize: ['20px', '18px', '20px', '20px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.02em',
    mb: ['20px', '20px', '22px', '22px', '22px']
  },
  processDescriptionStyle: {
    fontSize: ['15px', '15px', '15px', '15px'],
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.87'
  }
};

export default AmericaProcess;
