import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../../containers/SaasModern/sassModern.style';
import BannerSection from '../../containers/SaasModern/Banner';
import FaqSection from '../../containers/SaasModern/Faq';
import Footer from '../../containers/SaasModern/Footer';
import SEO from '../../components/seo';
import Typist from 'react-typist';
import FeaturedNavbar from '../../containers/SaasModern/Navbar/FeaturedNavBar';
import PageWrapper from '../../components/PageWrapper';
import { Modal } from '@redq/reuse-modal';
import * as Utils from '../../utils/Utils';
import AmericaProcess from '../../containers/SaasModern/WorkingProcess/AmericaProcess';
import AmericaInfo from '../../containers/SaasModern/Info/AmericaInfo';
import EveryFundedProcess from '../../containers/SaasModern/WorkingProcess/EveryFundedProcess';
import EveryFundedInfo from '../../containers/SaasModern/Info/EveryFundedInfo';


export default () => {
  return (
    <PageWrapper>
      <ThemeProvider theme={saasModernTheme}>
        <Fragment>
          <SEO title="Every Funded Company Ever"/>

          <ResetCSS/>
          <GlobalStyle/>

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <FeaturedNavbar buy={openEveryFundedCompanyCheckout}/>
              </DrawerProvider>
            </Sticky>
            <BannerSection
              heading={<>
                Every Funded Company Ever
              </>}
              descriptionText={<>
                Contact key decision makers at every funded company and start
                selling
                🚀 <br/>
                Here's what you get 👇 (178k companies)
              </>}
              airtableUrl="https://airtable.com/embed/shrKlIIGH28L0Ljjs"/>
            <EveryFundedProcess/>
            <EveryFundedInfo/>
            <FaqSection/>
            <Footer/>
          </ContentWrapper>
          <Modal/>
        </Fragment>
      </ThemeProvider>
    </PageWrapper>
  );
};

export const openEveryFundedCompanyCheckout = async () =>
  await Utils.openOneTimeCheckout('price_1HP4LpIDMN3fCm6jrFruxTga');

function Typer() {
  const [count, setCount] = React.useState(1);
  React.useEffect(() => {
    setCount(1);
  }, [count]);
  const techs = [
    'Shopify',
    'Stripe',
    'Google Maps',
    'Cloudflare',
    'Wordpress',
    'Google Adsense',
    'React',
    'Mailchimp',
    'Zendesk'
  ];
  const delay = 1000;
  return (
    <Typist
      className="inline-block"
      key={count}
      onTypingDone={() => setCount(0)}
      cursor={{
        blink: true,
        hideWhenDone: true
      }}
    >
      <span>{techs[0]}</span>
      <Typist.Backspace count={techs[0].length} delay={delay}/>
      <span>{techs[1]}</span>
      <Typist.Backspace count={techs[1].length} delay={delay}/>
      <span>{techs[2]}</span>
      <Typist.Backspace count={techs[2].length} delay={delay}/>
      <span>{techs[3]}</span>
      <Typist.Backspace count={techs[3].length} delay={delay}/>
      <span>{techs[4]}</span>
      <Typist.Backspace count={techs[4].length} delay={delay}/>
      <span>{techs[5]}</span>
      <Typist.Backspace count={techs[5].length} delay={delay}/>
      <span>{techs[6]}</span>
      <Typist.Backspace count={techs[6].length} delay={delay}/>
      <span>{techs[7]}</span>
      <Typist.Backspace count={techs[7].length} delay={delay}/>
      <span>{techs[8]}</span>
      <Typist.Backspace count={techs[8].length} delay={delay}/>
    </Typist>
  );
}